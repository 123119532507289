.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    min-width: 200px;
}

.container {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 20%;
}

.progressBar {
    background-color: var(--ts-blue) !important;
    height: 0.5rem !important;
    border-radius: 20px;
    min-width: 200px;
    transform: translate(-50%, -50%);
}