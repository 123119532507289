.title {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
}

.closeIcon:hover {
    cursor: pointer;
}

.input {
    width: 100%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border: 2px solid whitesmoke;
    font-family: Circular, sans-serif !important;
    font-weight: 100 !important;
}

.submitButton {
    width: 220px;
    margin-top: 20px;
    margin-bottom: 5px;
    background-color: white;
    font-family: Circular, sans-serif;
    padding: 7px 40px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: solid 2px var(--ts-blue);
    color: var(--ts-blue);
}

.submitButton:hover {
    cursor: pointer;
}

.errorMsg {
    font-size: 0.8rem;
    color: var(--ts-red-tertiary);
}

.progressBar {
    background-color: var(--ts-blue) !important;
    height: 0.5rem !important;
    border-radius: 20px;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

@media (max-width: 700px) {
    .submitButton {
        width: 100%;
    }
}