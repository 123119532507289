.app {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background-color: var(--ts-grey);
}

.ratesPage {
    position: relative;
    width: 100%;
}