.container {
    width: 55%;
    margin: auto;
    max-width: 1200px;
    padding-top: 2rem;
    height: fit-content;
}

.titleRow {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdowns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
}

.sectorDropdown {
    width: 58%;
}

.currencyDropdown {
    width: fit-content;
    margin-left: 25px;
    margin-top: 15px;
}

.ratingDropdown {
    width: 40%;
}

@media (min-width: 1500px) {
    .container {
        width: 45%;
    }
}

@media (max-width: 1100px) {
    .container {
        width: 70%;
    }
    .currencyDropdown {
        margin-top: 0;
    }
}

@media (max-width: 700px) {
    .container {
        width: 90%;
    }
    .dropdowns {
        flex-direction: column;
    }
    .sectorDropdown, .ratingDropdown {
        width: 100%;
    }
    .currencyDropdown {
        margin-top: -3px;
    }
    .titleRow {
        font-size: 2.5rem;
        justify-content: space-between;
    }
}