
.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, var(--ts-grey), var(--ts-grey) 80%, transparent 100%);
    border: none;
    height: var(--banner-height);
    z-index: 100;
}

.logo {
    width: 180px;
    position: fixed;
    top: 16px;
    left: 20px;
}

.icdLogo {
    height: 70px;
    position: fixed;
    bottom: 16px;
    right: 20px;
    opacity: 0.15;
}

.buttons {
    position: fixed;
    top: 28px;
    right: 30px;
    display: flex;
    flex-direction: row;
    z-index: 2000;
}

.loginButton, .signUpButton, .pdfButton {
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 1rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.loginButton, .pdfButton {
    border: solid 2px var(--ts-blue);
    color: var(--ts-blue);
}

.loginButton:hover, .pdfButton:hover {
    cursor: pointer;
}

.signUpButton, .loginButton, .pdfButton {
    margin-left: 20px;
}

.pdfButton {
    background-color: white;
}

.downloadIcon {
    padding-left: 3px;
}

.signUpButton {
    border: solid 2px var(--ts-green);
    color: var(--ts-green);
}

@media (max-width: 700px) {
    .logo {
        width: 140px;
        left: 10px;
        top: 10px;
    }
    .icdLogo {
        height: 60px;
        right: 10px;
        bottom: 10px;
    }
    .buttons {
        top: 12px;
        right: 10px;
    }
    .signUpButton, .loginButton, .pdfButton {
        margin-left: 10px;
    }
    .loginButton, .signUpButton, .pdfButton {
        padding: 5px 10px;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .buttons {
        bottom: 10px;
        top: auto
    }
    .icdLogo {
        top: 10px;
        height: 50px;
    }
}

/* @media (max-width: 360px) {
    .buttons {
        left: 5px;
        right: 5px;
        width: calc(100% - 10px);
    }
} */