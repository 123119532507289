.modal {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 24;
    padding: 2rem;
    overflow: wrap;
    overflow-wrap: break-word;
    outline: none;
}

@media (max-width: 900px) {
    .modal {
        width: 70%;
    }
}