:root {
  --ts-green: #00dc1e;
  --ts-green-light: #80ED8E;
  --ts-green-lightest: #D9FADD;
  --ts-blue: #273c4e;
  --ts-turquoise: #2EBF90;
  --ts-purple: #E8E8F1;
  --ts-purple-light: #F3F3F7;
  --ts-blue-50: #939DA6;

  --ts-orange-tertiary: #F0A045;
  --ts-orange-tertiary-light: #F7CFA2;
  --ts-blue-tertiary: #24B0CA;
  --ts-blue-tertiary-light: #92D7E4;
  --ts-blue-tertiary-25: #E4F5F8;
  --ts-blue-tertiary-10: #F4FBFC;
  --ts-red-tertiary: #FF5C5C;
  --ts-red-tertiary-light: #FFADAD;

  --ts-grey: #F6F7F8;

  --banner-height: 100px;

  font-size: 16px;
  font-family: Circular, sans-serif;
  font-weight: 500;
  color: var(--ts-blue);
}

body {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 3.5rem;
  margin: 0;
}

p {
  font-weight: 100;
}

.up {
  color: var(--ts-turquoise) !important;
}

.new {
  color: var(--ts-orange-tertiary) !important;
}

.green {
  background-color: var(--ts-turquoise);
}

.orange {
  background-color: var(--ts-orange-tertiary);
}

.MuiFormControl-root.MuiTextField-root {
  border: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 2px solid whitesmoke !important;
  border-radius: 8px !important;
}

.MuiInputLabel-root {
  color: lightgray !important;
  font-family: Circular, sans-serif !important;
  font-weight: 100 !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
  color: var(--ts-green) !important;
}

.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
  background-color: var(--ts-green) !important;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-Black.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-Book.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-Light.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-BookItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/Circular/CircularStd-LightItalic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Arimo';
  src: url('./fonts/Arimo/Arimo-Bold.ttf');
  font-weight: 900;
}

@media (max-width: 1000px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  :root {
    font-size: 12px;
    --banner-height: 75px;
  }
}