.chart {
    border-radius: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: white;
}

.chartFooter {
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
}

.updatedTime {
    font-weight: 400;
    color: var(--ts-blue-50);
    text-align: right;
}

.updatedTime strong {
    color: var(--ts-blue);
}

.disclaimer {
    width: fit-content;
    color: var(--ts-blue);
}

.shortDisclaimer {
    font-size: 0.7rem;
}

@media (max-width: 300px) {
    .updatedTime {
        font-size: 0.6rem;
    }
}

@media(max-width: 700px) {
    .shortDisclaimer {
        font-size: 0.8rem;
    }
}
