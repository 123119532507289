.calcButton {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    background-color: var(--ts-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
    border-radius: 100px;
    box-shadow: 0 2px 20px -7px gray;
}

.calcButton svg {
    transform: scale(1.8);
    color: var(--ts-blue);
}

.calcButton:hover {
    cursor: pointer;
}

.container {
    padding: 1.5rem;
    overflow: wrap;
    overflow-wrap: break-word;
    position: fixed;
    bottom: 2rem;
    left: 7.5rem;
    border: 2px solid whitesmoke;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 20px -7px gray;
    transform-origin: 0 90%;
    margin-right: 2rem;
}

.title {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    vertical-align: center;
}

.title h4 {
    margin-top: 0;
    margin-right: 2rem;
    font-size: 1.1rem;
    margin-bottom: 1.1rem;
}

.closeIcon:hover {
    cursor: pointer;
}

.input {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0.5rem;
    border: 2px solid whitesmoke;
    font-family: Circular, sans-serif !important;
    font-weight: 100 !important;
}

.amountInWords {
    font-size: 0.8rem;
    font-style: italic;
    opacity: 0.5;
    margin-top: 0.2rem;
}

.calcOptions {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    margin-top: 1.2rem;
}

.calcOptions div:not(:last-child) {
    margin-right: 1.8rem;
    margin-bottom: 0.7rem;
}

.calcOptions div:not(.selected) {
    opacity: 0.35;
}

.calcOptions div:hover {
    cursor: pointer;
    opacity: 1;
}

.tsGreen {
    color: var(--ts-green);
}

.tsRed {
    color: var(--ts-red-tertiary);
}

.disclaimer {
    font-size: 0.7rem;
    margin-bottom: 0;
    margin-top: 1rem;
}

.container h3 {
    margin-bottom: 0.7rem;
    margin-top: 0.8rem;
    font-size: 1rem;
}

.container h1, .modal h1 {
    font-size: 2.3rem;
}

.container h5 {
    margin-bottom: 0;
    margin-top: 0.5rem;
    font-weight: 400;
}

@media (max-width: 450px) {
    .calcButton {
        bottom: 1rem;
        left: 1rem;
        padding: 1.4rem;
    }
    .calcButton svg {
        transform: scale(1.8);
    }
    .container {
        left: 1rem;
        bottom: 5.6rem;
        transform-origin: 2rem 100%;
    }
    .calcOptions {
        font-size: 0.7rem;
    }
    .disclaimer {
        font-size: 0.6rem;;
    }
    .title h4 {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }
    .amountInWords {
        font-size: 0.7rem;
    }
    .container {
        padding: 1.2rem;
        margin-right: 1rem;
    }
}