.menu {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px gainsboro;
    /* border: 2px solid whitesmoke; */
}

.menu > ul > li {
    font-family: 'Circular', sans-serif;
}

.menu > ul > li[aria-selected=true], 
.menu > ul > li:active, 
.menu > ul > li[aria-selected=true]:hover, 
.menu > ul > li[aria-selected=true]:focus {
    background-color: var(--ts-blue);
    color: white;
}