.table {
    width: 100%;
    margin-bottom: 25px;
}

.table th,
.table td {
    text-align: center;
    width: 9%;
    border-radius: 8px;
}
.table td {
    font-size: 1.4rem;
    padding: 0.4rem 0;
}
.table th {
    font-size: 1.2rem;
}

.table tr {
    height: 30px;
}

.table td:hover {
   cursor: pointer;
   background-color: white;
}

@media(max-width: 1200px) {
    .table td {
        font-size: 1.3rem;
    }
    .table th {
        font-size: 1.1rem;
    }
}

@media(max-width: 800px) {
    .table th {
        font-size: 0.9rem;
    }
}

@media(max-width: 700px) {
    .table td {
        font-size: 1.1rem;
    }
}

@media(max-width: 600px) {
    .table th {
        font-size: 0.8rem;
    }
}

