.container {
    margin-top: 50vh;
    transform: translate(0, -50%);
    padding: 20px;
}

.logo {
    width: max(300px, 20%);
    display: flex;
    margin: auto;
    margin-bottom: 50px;
}

.container > h1 {
    font-size: 2rem;
    text-align: center;
}

.container > p {
    font-size: 1.3rem;
    text-align: center;
}

.buttonRow {
    position: fixed;
    top: 28px;
    right: 30px;
    display: flex;
    flex-direction: row;
    z-index: 2000;
}

.loginButton, .signUpButton {
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 1rem;
    background-color: white;
}

.loginButton {
    border: solid 2px var(--ts-blue);
    color: var(--ts-blue);
}

.loginButton:hover {
    cursor: pointer;
}

.signUpButton, .loginButton {
    margin-left: 20px;
}

.signUpButton {
    border: solid 2px var(--ts-green);
    color: var(--ts-green);
}

@media (max-width: 700px) {
    .buttonRow {
        top: 12px;
        right: 10px;
    }
    .signUpButton, .loginButton {
        margin-left: 10px;
    }
    .loginButton, .signUpButton {
        padding: 5px 10px;
        font-size: 1rem;
    }
}

@media (max-width: 500px) {
    .buttonRow {
        bottom: 10px;
        top: auto
    }
}

@media (max-width: 360px) {
    .buttonRow {
        left: 5px;
        right: 5px;
        width: calc(100% - 10px);
    }
}